import { getBaseUrl } from "./utils/baseUrlHelper";

export const localBaseUrl = "http://dev.api.rankplus.live/"
// export const localBaseUrl = "https://api.rankplus.live/";
export const testBaseUrl = "https://dev.api.rankplus.live/";
export const liveBaseUrl = "https://api.rankplus.live/";
export const strApiBaseUrl = "https://cms.rankplus.live/api";

export const websiteUrl = "https://web.rankplus.live";

const isclient = typeof window !== "undefined";
if (isclient) {
  console.log("[loc]", window.location);
}
export const BaseURL = isclient ? getBaseUrl(window.location?.hostname) : liveBaseUrl;
