import {
  liveBaseUrl,
  localBaseUrl,
  testBaseUrl,
} from "../BaseUrl";

export function getBaseUrl(host) {
  console.log(host, localBaseUrl);
  if (host === "localhost" || host === "127.0.0.1") {
    return testBaseUrl;
  } else if (host === "dev.student.rankplus.live") {
    return testBaseUrl;
  } else if (host === "student.rankplus.live" || host === "rankplus.live") {
    return liveBaseUrl;
  } else {
    return liveBaseUrl;
  }
}
