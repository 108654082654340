import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import thunk from "redux-thunk"
import { STATUS } from "../../../App/Constants"
import { removeAuthorizationHeader } from "../../../services/api"
import { apis } from "../../../services/api/apis"
import { useToast } from '@chakra-ui/react'
import { LoadingRef } from "../../../App/AppProvider"



// Feedback 
export const createUserFeedbackAction = createAsyncThunk(
    "feedback/post",
    async (payload, thunkAPI) => {
        // const response = {}
        // console.log("createUserFeedbackAction Called");
        const response = await apis.requestUserFeedbackApi(payload)
        const { ok, problem, data } = response
        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)

//Create Lecture Feedback 
export const createLectureFeedbackAction = createAsyncThunk(
    "create/lecture/feedback/post",
    async (payload, thunkAPI) => {
        // const response = {}
        // console.log("createUserFeedbackAction Called");
        const response = await apis.createLectureFeedbackApi(payload)
        const { ok, problem, data } = response
        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)
//Update Lecture Feedback 
export const updateLectureFeedbackAction = createAsyncThunk(
    "update/lecture/feedback/post",
    async (payload, thunkAPI) => {
        // const response = {}
        // console.log("createUserFeedbackAction Called");
        const response = await apis.updateLectureFeedbackApi(payload)
        const { ok, problem, data } = response
        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)

const initialState = {
}

const feedbackSlice = createSlice({
    name: "feedback",
    initialState,
    reducers: {
        //   hello : 'world'
    },
    extraReducers: {
        // Order
        [createUserFeedbackAction.pending]: (state, action) => {
            state.getFeedbackStatus = STATUS.FETCHING;
        },

        [createUserFeedbackAction.rejected]: (state, action) => {
            state.getFeedbackStatus = STATUS.FAILED;
        },

        [createUserFeedbackAction.fulfilled]: (state, action) => {
            state.getFeedbackStatus = STATUS.SUCCESS;
            // console.log("createUserFeedbackAction.fulfilled : ", {state,action})
            state.feedbackData = action.payload;
        },
        [createLectureFeedbackAction.pending]: (state, action) => {
            state.getCreateLectureStatus = STATUS.FETCHING;
        },

        [createLectureFeedbackAction.rejected]: (state, action) => {
            state.getCreateLectureStatus = STATUS.FAILED;
        },

        [createLectureFeedbackAction.fulfilled]: (state, action) => {
            state.getCreateLectureStatus = STATUS.SUCCESS
            // console.log("updateLectureFeedbackAction.fulfilled : ", {state,action})
            state.lectureData = action.payload;
        },
        [updateLectureFeedbackAction.pending]: (state, action) => {
            state.getUpdateLectureStatus = STATUS.FETCHING;
        },

        [updateLectureFeedbackAction.rejected]: (state, action) => {
            state.getUpdateLectureStatus = STATUS.FAILED;
        },

        [updateLectureFeedbackAction.fulfilled]: (state, action) => {
            state.getUpdateLectureStatus = STATUS.SUCCESS
            // console.log("updateLectureFeedbackAction.fulfilled : ", {state,action})
            state.updateLectureData = action.payload;
        },

    },
})

export const feedbackReducer = feedbackSlice.reducer